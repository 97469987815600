import {
    ENERGY_TYPES,
    DEFAULT_ORDER_BY,
    DEFAULT_SORT_DIRECTION,
    PAGE_ID,
    DEFAULT_ZIP,
    DEFAULT_ENERGY_TYPE,
    DEFAULT_PAGE_ID,
    DEFAULT_MARKETPLACE_ID,
    DEFAULT_FILTER_BY,
    DEFAULT_CONSUMPTION,
    DEFAULT_SIGN_UP_FLOW,
    PRODUCER_TYPES
} from '../../../constants';
import { formatTextToURL } from './url';

// TODO: It is fast hack. Get rid of the method and refactoring this logic (R, @Anton)
// FIXME: Try const params = new URLSearchParams({
//   var1: "value",
//   var2: "value2",
//   arr: "foo",
//   }); Please check with german characters
const fixURLChars = str => {
    const fixedString = str.replace('?&', '?');
    const lastSymbol = fixedString.slice(-1);
    if (lastSymbol === '?') {
        return fixedString.replace('?', '');
    }
    return fixedString;
};

export function constructStandardUrlParams({
    zip = DEFAULT_ZIP,
    consumption = DEFAULT_CONSUMPTION,
    energyType = DEFAULT_ENERGY_TYPE,
    houseNumber,
    streetName,
    cityName,
    iframe,
    HT: dayConsumption,
    NT: nightConsumption,
    isTimeDependingConsumption
}) {
    const zipParam = zip && zip !== DEFAULT_ZIP ? `zip=${zip}` : '';
    const consumptionParam = consumption ? `consumption=${consumption}` : '';
    const energyTypeParam = energyType ? `energyType=${energyType}` : '';
    const houseNumberParam = houseNumber ? `houseNumber=${houseNumber}` : '';
    const streetNameParam = streetName ? `streetName=${encodeURI(streetName, 'ISO-8859-1')}` : '';
    const cityNameParam = cityName ? `cityName=${encodeURI(cityName, 'ISO-8859-1')}` : '';
    const iframeParam = iframe ? 'iframe=true' : '';
    const dayConsumptionParam = dayConsumption ? `HT=${dayConsumption}` : '';
    const nightConsumptionParam = nightConsumption ? `&NT=${nightConsumption}` : '';

    const preparedConsumptionParam = isTimeDependingConsumption ?
        dayConsumptionParam + nightConsumptionParam :
        consumptionParam;

    const standardParams = [
        zipParam,
        preparedConsumptionParam,
        houseNumberParam,
        streetNameParam,
        cityNameParam,
        energyTypeParam,
        iframeParam
    ].filter(param => !!param);

    return `?${standardParams.join('&')}`;
}

export function constructSignupUrl(params) {
    const {
        tariffId,
        signupFlow = DEFAULT_SIGN_UP_FLOW,
        identifier = DEFAULT_PAGE_ID,
        producerId,
        energySubTypeId
    } = params;

    const tariffIdParam = tariffId ? `tariffId=${tariffId}` : '';
    const producerIdParam = producerId ? `producerId=${producerId}` : '';
    const signupFlowParam = signupFlow && signupFlow !== DEFAULT_SIGN_UP_FLOW ? `signupFlow=${signupFlow}` : '';
    const energySubTypeIdParam = energySubTypeId ? `energySubTypeId=${energySubTypeId}` : '';
    const identifierParam = identifier === PAGE_ID.REGIONAL_CAMPAIGN ? PAGE_ID.STANDARD : identifier;

    const isTimeDependingConsumption = !!(params?.HT && params?.NT);

    const standardParams = constructStandardUrlParams({
        ...params,
        isTimeDependingConsumption
    });
    const signUpParams = [producerIdParam, signupFlowParam, tariffIdParam, energySubTypeIdParam].filter(
        param => !!param
    );

    const part1 = `${standardParams}`;
    const part2 = `${signUpParams.length ? '&' : ''}${signUpParams.join('&')}`;

    return fixURLChars(`/signup/${identifierParam}${part1}${part2}`);
}

export function constructExtraProducersUrlParams({
    ascDesc = DEFAULT_SORT_DIRECTION,
    orderBy = DEFAULT_ORDER_BY,
    isEcoenergy = false,
    identifier = DEFAULT_PAGE_ID,
    marketplaceId = DEFAULT_MARKETPLACE_ID,
    filterBy = DEFAULT_FILTER_BY,
    signupFlow = DEFAULT_SIGN_UP_FLOW,
    energySubTypeId
}) {
    const ascDescParam = ascDesc && ascDesc !== DEFAULT_SORT_DIRECTION ? `ascDesc=${ascDesc}` : '';
    const orderByParam = orderBy ? `orderBy=${orderBy}` : '';
    const isEcoenergyParam = isEcoenergy ? 'isEcoenergy=true' : '';
    const identifierParam = identifier && identifier !== DEFAULT_PAGE_ID ? `identifier=${identifier}` : '';
    const marketplaceIdParam =
      marketplaceId && marketplaceId !== DEFAULT_MARKETPLACE_ID ? `marketplaceId=${marketplaceId}` : '';
    const filterByParam =
      filterBy && filterBy !== DEFAULT_FILTER_BY ?
          `filterBy=${filterBy}` :
          `filterBy=${Object.values(PRODUCER_TYPES)
              .filter(type => type !== DEFAULT_FILTER_BY)
              .join(',')}`;
    const energySubTypeIdParam = energySubTypeId ? `energySubTypeId=${energySubTypeId}` : '';
    const signupFlowParam = signupFlow && signupFlow !== DEFAULT_SIGN_UP_FLOW ? `signupFlow=${signupFlow}` : '';

    const extraProducerParams = [
        identifierParam,
        isEcoenergyParam,
        marketplaceIdParam,
        energySubTypeIdParam,
        ascDescParam,
        orderByParam,
        filterByParam,
        signupFlowParam
    ].filter(param => !!param);

    return `${extraProducerParams.length ? '&' : ''}${extraProducerParams.join('&')}`;
}

export function constructProducerDetailsUrl(params, producerName = '') {
    const { producerId, tariffId, signupFlow = DEFAULT_SIGN_UP_FLOW } = params;

    const tariffIdParam = tariffId ? `tariffId=${tariffId}` : '';
    const signupFlowParam = signupFlow && signupFlow !== DEFAULT_SIGN_UP_FLOW ? `signupFlow=${signupFlow}` : '';

    const isTimeDependingConsumption = !!(params?.HT && params?.NT);

    const formattedProducerName = formatTextToURL(producerName);

    const standardParams = constructStandardUrlParams({
        ...params,
        isTimeDependingConsumption
    });
    const extraProducerParams = constructExtraProducersUrlParams(params);

    const producerDetailsParams = [tariffIdParam, signupFlowParam].filter(param => !!param);

    const part1 = `${standardParams}`;
    const part2 = `${producerDetailsParams.length ? '&' : ''}${producerDetailsParams.join('&')}`;
    const part3 = `${extraProducerParams}`;

    return fixURLChars(`/producer-details/${producerId}-${formattedProducerName}${part1}${part2}${part3}`);
}

export function constructProducersUrl({ identifier, ...params }) {
    return fixURLChars(
        `/${PAGE_ID.PRODUCERS}${identifier ? `/${identifier}` : ''}/${constructStandardUrlParams(params)}${constructExtraProducersUrlParams(params)}`
    );
}

export function constructContractUrl(params) {
    const { identifier = DEFAULT_PAGE_ID } = params;
    return fixURLChars(
        `/contract/${identifier}${constructStandardUrlParams(params)}${constructExtraProducersUrlParams(params)}`
    );
}

export function constructThankYouUrl(params) {
    const {
        identifier = DEFAULT_PAGE_ID,
        txId,
        processing,
        otherSupplier,
        energyType = DEFAULT_ENERGY_TYPE,
        energySubTypeId
    } = params;

    const txIdParam = txId ? `txId=${txId}` : '';
    const processingParam = processing ? `processing=${processing}` : '';
    const otherSupplierParam = otherSupplier ? `otherSupplier=${otherSupplier}` : '';
    const energyTypeParam = energyType && energyType !== DEFAULT_ENERGY_TYPE ? `energyType=${energyType}` : '';
    const energySubTypeIdParam = energySubTypeId ? `energySubTypeId=${energySubTypeId}` : '';

    const thankyouParams = [
        txIdParam,
        processingParam,
        otherSupplierParam,
        energyTypeParam,
        energySubTypeIdParam
    ].filter(param => !!param);

    return `/thankyou/${identifier}${thankyouParams.length ? '?' : ''}${thankyouParams.join('&')}`;
}

export function buildTariffsPageUrl(params) {
    const { tariffsPageUrl } = params;
    const url = tariffsPageUrl;

    // TODO: Make the logic more clear.
    // When the energyType is gas we always should go to contract page to show the gas tariff
    const isShouldConstructContractUrl = !url || params.energyType === ENERGY_TYPES.GAS;

    if (isShouldConstructContractUrl) {
        return constructContractUrl(params);
    }

    if (url.includes(`/${PAGE_ID.PRODUCERS}`)) {
        const updatedParams = params.isRegionalCampaignPage ?
            { ...params, identifier: PAGE_ID.REGIONAL_CAMPAIGN } :
            params;
        return constructProducersUrl(updatedParams);
    }
    return `${url}${constructPageURLParams(params)}`;
}

export function constructPageURLParams(params) {
    return fixURLChars(`${constructStandardUrlParams(params)}${constructExtraProducersUrlParams(params)}`);
}

import get from 'lodash/get';
import {
    DEFAULT_ENERGY_TYPE,
    DEFAULT_ORDER_BY,
    DEFAULT_PAGE_ID,
    DEFAULT_SORT_DIRECTION,
    ERRORS
} from '../../constants';
import LocalStorage from '../../services/localStorage';
import { isInIframe, delayedFunctionCall } from '../../services/helpers/utils';
import * as GTM from '../../services/helpers/gtm';
import { constructPageURLParams } from '../../services/helpers/url/urlBuilder';
import * as api from '../../services/api';
import { isTestEnv } from '../../services/helpers/env';

export function setLoadingStateHandler(value = true) {
    this.setState({ isLoading: !!value });
}

export function switchUrlPathHandler(url) {
    this.setLoadingStateHandler();

    if (url && typeof url === 'string' && window && window.location) {
        // FIXME: Use react router @Anton
        window.location.assign(url);
    }
}

// TODO: Cover by unit tests
export function scrollToTopIfIframe() {
    if (!process.browser) {
        return;
    }

    try {
        const shouldScrollToTop = () => isInIframe() && window?.parentIFrame;
        const scrollParentPageToTop = () => window.parentIFrame?.scrollTo(0, 0);

        if (shouldScrollToTop()) {
            scrollParentPageToTop();
        } else {
            delayedFunctionCall(shouldScrollToTop, scrollParentPageToTop);
        }
    } catch (error) {
        console.error(error);
    }
}

export function scrollToAnchor(element) {
    if (window && !!process.browser) {
        window.location.assign(`#${element}`);
    }
}

export function handlePagePasswordSubmit(password) {
    const {
        content: { accessPassword, additionalData: { lockForm: { accessPageError = ERRORS.TRY_AGAIN } = {} } = {} }
    } = this.props;
    const { pageProtection } = this.state;

    if (password === accessPassword) {
        this.setState({ pageProtection: { ...pageProtection, password } });
        // TODO: Better if we set an accessPassword with some special key, becuase
        // if we have pages with a password, we must enter the password every time when
        // we switch between the pages.
        // return asyncLocalStorage.setItem('accessPassword' + identifier, password); (R)
        return LocalStorage.setAccessPasswordAsync(password);
    }
    return this.setState({ pageProtection: { ...pageProtection, error: accessPageError } });
}

export function handleTariffCalculationFormSubmit(params) {
    const {
        query,
        content: { defaultSortBy: defaultOrder = DEFAULT_ORDER_BY, tariffCalculationForm }
    } = this.props;
    const { energyType = DEFAULT_ENERGY_TYPE, metaData: { place } = {} } = params;
    const { orderBy = defaultOrder, ascDesc = DEFAULT_SORT_DIRECTION, identifier = DEFAULT_PAGE_ID } = query;
    const isEcoenergy = get(tariffCalculationForm, 'fields.isEcoenergy', query.isEcoenergy);

    const newParams = {
        ...query,
        ...params,
        ascDesc,
        orderBy: orderBy || defaultOrder,
        isEcoenergy,
        identifier
    };

    GTM.addSubmitTariffCalcDataToDL({ energyType, place });

    // FIXME: use router.push() @Anton
    if (window.self !== window.top) {
        window.parent.postMessage({ submit: constructPageURLParams(newParams) }, '*');
    }
    window.location.assign(constructPageURLParams(newParams));
}

export async function handleGeneralFormSubmit(formData, eventType) {
    try {
        await api.sendLeadDataToBE({ ...formData, eventType });
    } catch (error) {
        if (!isTestEnv()) {
            console.error(error);
        }
        // TODO: May be should be in state "sendLeadDataToBEResult: { data: {}, error: null, loading: false }" and
        //  handled by used method components or use mobX or redux
        this.setState({ commonServerErrorDetails: error });
    }
}
